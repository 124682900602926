import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CommonContext } from '../../../context';
import Layout from '../../../components/layout';
import Pagination from '../../../components/Pagination';
import { capitalizeFirstLetter } from '../../../utils';

export const query = graphql`
  query ($slug: String!) {
    wpCategory(slug: { eq: $slug }) {
      name
      slug
    }
    allWpPost(
      sort: { fields: modified, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        slug
        title
        excerpt
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }
`;

const PAGE_SIZE = 5;

function CategoryArchive({ data, location }) {
  const category = data.wpCategory;
  const [pageNo, setPageNo] = useState(1);
  const blogData = [...data.allWpPost.nodes];
  const maxPageNo = Math.ceil(blogData.length / PAGE_SIZE);

  const getPaginatedData = () => {
    const startIndex = (pageNo - 1) * PAGE_SIZE;
    return blogData.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const renderBlogItem = (item, idx) => (
    <Link to={`/blog/${item.slug}/`} key={`blog-item-${idx}`}>
      <div className="mb-12 md:flex">
        <GatsbyImage
          className="mb-4 mr-4 md:w-96 rounded-2xl md:mb-6 overflow-clip"
          image={item.featuredImage.node.gatsbyImage}
          alt={item.featuredImage.node.altText}
        />
        <div className="max-w-lg">
          <div className="mb-2 text-sm font-regular">
            <span className="uppercase">
              {item.categories.nodes.map((cat) => cat.name).join(' | ')}
            </span>{' '}
            | {item.modified}
          </div>
          <div className="mb-2 font-semiBold md:mb-4">{item.title}</div>
          <p dangerouslySetInnerHTML={{ __html: item.excerpt }}></p>
        </div>
      </div>
    </Link>
  );

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title={`Category: ${category.name}`} location={location}>
        <div className="flex justify-center w-full from-primary-2 bg-gradient-to-r to-primary-3">
          <div className="w-full max-w-6xl px-6">
            <div className="flex justify-center text-xl py-7 font-regular md:text-3xl">
              <div className="w-full max-w-6xl">
                {capitalizeFirstLetter(category.name)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="w-full max-w-6xl px-6 pt-12">
            {getPaginatedData().map((item, idx) => renderBlogItem(item, idx))}
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-36">
          <Pagination
            pageNo={pageNo}
            setPageNo={setPageNo}
            maxPageNo={maxPageNo}
          />
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

CategoryArchive.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default CategoryArchive;
